import React, { useState } from "react"
// import { Link } from "gatsby"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormSuccessMessage from "../../components/form-success-message/form-success-message"

import "../../styles/services.scss"

const HoroscopeMatching = () => {
  const [submissionStatus, setSubmissionStatus] = useState("")
  const handleSubmissionStatus = axiosStatus => {
    setSubmissionStatus(axiosStatus)
  }

  return (
    <Layout>
      <SEO
        title="Horoscope matching"
        description="Determine if he/she is destined to be your better half."
        keyword="Horoscope matching, astrology, online"
      />
      <div className="main-sub-wrapper">
        <div className="header-wrap">
          <h1 className="header__title h1_decorative">Horoscope matching</h1>
          {/* <div className="tg-list-item">
            <input
              className="tgl tgl-flip"
              id="language-checkbox"
              type="checkbox"
            />
            <label
              className="tgl-btn"
              data-tg-off="മലയാളം"
              data-tg-on="English"
              htmlFor="language-checkbox"
            ></label>
          </div> */}
          <div className="text-left">
            <p>
              Why we look up the horoscope, matches, "Papasamyas",
              "Dhashasandhi" etc?
            </p>
            <p>
              In Hindu marriages, horoscope is considered an integral part. The
              first step towards a married life is the checking of the horoscope
              match.
            </p>
            <p>
              As per "Shashankaradeeyam",
              <br />
              "Nissanthane sasunum, niravadi karunam nirduaye, niswathandye,
              nisthulyam vithanatham, sarujamaruji cha vidyarahitha, ithi
              mruthisthaana dhosham samana lokya anishta khedan muhurapi manasa
              yojoyeth buddhishali...."
            </p>
            <p>
              If the horoscope matches perfectly for the couple, the ones
              lacking in spheres like children, wealth, longevity etc gains
              resolution in the matters. Also, it is believed that the one
              having "Daridrayoga" should be matched with one with "Dhanayoga"
              in order for stable married life.
            </p>
            <p>
              It is as per the planetary movements and their directions that the
              astrologers calculate the horoscope. The revolving of the planets
              is observed in order to provide the personal characteristics of
              the people. As per this, once the married couple lives together,
              their stars and planets influence their lives. Hence, their star
              compatibility is checked.
            </p>
          </div>
          {/* <div className="text-left malayalam">
            <p>
              എന്തിനാണ് ജാതകം പൊരുത്തവും, പാപസാമ്യവും, ദശാസന്ധിയും നോക്കുന്നത്?
            </p>
            <p>
              ഹിന്ദു വിവാഹങ്ങളില്‍ പ്രധാനപ്പെട്ട കാര്യമായിട്ടാണ് ജാതകത്തെ
              കണക്കാക്കുന്നത്. വൈവാഹിക ജീവിതത്തിനുള്ള ആദ്യ പടിയായാണ് ജാതകം
              പൊരുത്തം നോക്കുന്നത്.
            </p>
            <p>
              ശശാങ്കശാരദീയത്തിൽ പറയുന്നു
              <br />
              നിസ്സന്താനേ സസൂനൂം, നിരവധി കരുണം നിർദ്ദയേ, നിസ്വതാന്ധ്യേ,
              നിസ്തുല്യം വിത്തനാഥം, സരൂജമരൂജി ച, ക്ഷ്മാജൂഷാം ജാതകേഷൂ,
              വിദ്യാവൃദ്ധം ച വിദ്യാരഹിത, ഇതി മൃതിസ്ഥാന ദോഷം സമാനാ ലോക്യാ അനിഷ്ട
              ഖേടാൻ മുഹുരപി മനസാ യോജേയേത് ബുദ്ധിശാലി
            </p>
            <p>
              വരന്‍റെയും വധുവിന്റെയും ജാതകം തമ്മില്‍ നല്ല രീതിയില്‍
              ചേരുന്നുവെങ്കില്‍ സന്താന ഭാഗ്യമില്ലാതെ ആൾക്ക് ഭാഗ്യമുള്ളതും,
              നിഷ്കരുണണ് - സഹനശേഷി ഉള്ളവളും ദീര്‍ഘായുസ്സുള്ള ദാമ്പത്യം
              ഇല്ലാത്തവന് - അത് ഉള്ളതും ദാരിദ്ര യോഗമുള്ളവന് ധനയോഗമൂള്ളവളും
              യോജിപിച്ചാൽ മാത്രമേ ദാമ്പത്യം ഉണ്ടാകുമെന്നാണ് വിശ്വാസം.
              ഗ്രഹങ്ങളുടെ പരിക്രമണ ദിശയില്‍ നിന്നാണ് ജ്യോതിഷികള്‍ ജാതകം
              നോക്കുന്നത്. ഗ്രഹങ്ങളുടെ തിരിയല്‍ അനുസരിച്ചാകും വ്യക്തിപരമായ
              പ്രത്യേകതകള്‍ വെളിപ്പെടുന്നത്. ഇതിനാല്‍ ദമ്പതികള്‍ ഒന്നിച്ചു
              കഴിയുമ്പോള്‍ പങ്കാളിയുടെ ജീവിതത്തില്‍ അവരുടെ നക്ഷത്രങ്ങളും
              ഗ്രഹങ്ങളും സ്വാധീനം ചെലുത്തും. ഇതാണ് രണ്ടു പേരുടെയും നക്ഷത്ര
              പൊരുത്തം നോക്കുന്നത്.
            </p>
          </div> */}
        </div>
        <div className="service-inner-wrap">
          <h2 className="h4">Process and Fees</h2>
          <p>
            Once you submit the form and make the payment, our astrologers get
            to the calculations and will give you the result over the contact
            address you mention in the email. If you have questions after
            reading the result, then you can always communicate with our
            astrologers over email, phone, Skype etc.
          </p>
          <p>
            <b>Fees:</b> ₹500 (INR) or $10 (USD)
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              applicantRelationship: "",
              brideName: "",
              brideDob: "",
              brideTob: "",
              bridePob: "",
              brideStar: "",
              groomName: "",
              groomDob: "",
              groomTob: "",
              groomPob: "",
              groomStar: "",
              message: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.name) {
                errors.name = "Please enter you name"
              }
              if (!values.email) {
                errors.email =
                  "Please enter you e-mail or phone number so that we could contact you"
              } else if (
                !(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  ) || /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(values.email)
                )
              ) {
                errors.email = "Invalid email or number"
              }
              if (!values.applicantRelationship) {
                errors.applicantRelationship =
                  "Please enter you relationship with the bride/groom"
              }
              if (!values.brideName) {
                errors.brideName = "Please enter bride's name"
              }
              if (!values.brideDob) {
                errors.brideDob = "Please enter bride's date of birth"
              }
              if (!values.brideTob) {
                errors.brideTob = "Please enter bride's time of birth"
              }
              if (!values.bridePob) {
                errors.bridePob = "Please enter bride's place of birth"
              }
              if (!values.brideStar) {
                errors.brideStar = "Please enter bride's star"
              }
              if (!values.groomName) {
                errors.groomName = "Please enter groom's name"
              }
              if (!values.groomDob) {
                errors.groomDob = "Please enter groom's date of birth"
              }
              if (!values.groomTob) {
                errors.groomTob = "Please enter groom's time of birth"
              }
              if (!values.groomPob) {
                errors.groomPob = "Please enter groom's place of birth"
              }
              if (!values.groomStar) {
                errors.groomStar = "Please enter groom's star"
              }
              if (values.policy === true) {
                errors.policy = "Please check to proceed"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              axios
                .post("https://submit-form.com/DEx99InC", values)
                .then(res => {
                  handleSubmissionStatus("success")
                })
                .catch(err => {
                  handleSubmissionStatus("failure")
                  console.log(err)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                {submissionStatus === "failure" ? (
                  <p className="form__main-error">
                    Sorry, please send again, the message wasn't sent.
                  </p>
                ) : (
                  ""
                )}

                {submissionStatus === "success" ? (
                  <FormSuccessMessage stripeLinkINR="https://buy.stripe.com/cN26oY4CI9hn07e6or"></FormSuccessMessage>
                ) : (
                  <>
                    <h6 className="form__section-title">
                      Details of you - the applicant
                    </h6>
                    <div className="form__group">
                      <label htmlFor="name" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="name"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="email" className="form__label">
                        Contact Info*
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        autocomplete="email"
                        className="form__input"
                        aria-required="true"
                        placeholder="e-mail or phone number"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="applicantRelationship"
                        className="form__label"
                      >
                        Relationship with the bride/groom*
                      </label>
                      <Field
                        type="text"
                        name="applicantRelationship"
                        id="applicantRelationship"
                        className="form__input"
                        placeholder="I am his/her"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="applicantRelationship"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <h6 className="form__section-title">
                      Details of the bride
                    </h6>
                    <div className="form__group">
                      <label htmlFor="brideName" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="brideName"
                        id="brideName"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="brideName"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="brideDob" className="form__label">
                        Date of birth*
                      </label>
                      <Field
                        type="date"
                        max={new Date().getFullYear() - 17 + "-01-01"}
                        name="brideDob"
                        id="brideDob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="brideDob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="brideTob" className="form__label">
                        Time of birth*
                      </label>
                      <Field
                        type="time"
                        name="brideTob"
                        id="brideTob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="brideTob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="bridePob" className="form__label">
                        Place of birth* (nearest city)
                      </label>
                      <Field
                        type="text"
                        name="bridePob"
                        id="bridePob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="bridePob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="brideStar" className="form__label">
                        Nakshatra/Birthstar*
                      </label>
                      <Field
                        as="select"
                        name="brideStar"
                        id="brideStar"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="unknown">
                          Unknown (its fine, we will calculate it.)
                        </option>
                        <option value="aswathi">Aswathi/Ashwini</option>
                        <option value="bharani">Bharani</option>
                        <option value="karthika">Karthika/Krittika</option>
                        <option value="rohini">Rohini</option>
                        <option value="makeeram">Makeeram/Mrigashirsa</option>
                        <option value="thiruvathira">Thiruvathira/Ardra</option>
                        <option value="punartham">
                          Punartham/Punarvasu/Punarpoosam
                        </option>
                        <option value="pooyam">Pooyam/Pushya</option>
                        <option value="aayilyam">Aayilyam/Ashlesha</option>
                        <option value="makam">Makam/Magha</option>
                        <option value="pooram">Pooram/Purva Phalguni</option>
                        <option value="uthran">Uthram/Uttara Phalguni</option>
                        <option value="atham">Atham/Hasta</option>
                        <option value="chithira">Chithira/Chitra</option>
                        <option value="chothi">Chothi/Swati</option>
                        <option value="vishaakam">Vishaakham/Vishakha</option>
                        <option value="anizham">
                          Anizham/Anuradha/Anusham
                        </option>
                        <option value="thriketta">
                          Thriketta/Jyeshtha/Kettai
                        </option>
                        <option value="moolam">Moolam/Mula</option>
                        <option value="pooraadam">Pooraadam/Purvashada</option>
                        <option value="uthraadam">Uthraadam/Uthrashada</option>
                        <option value="thiruvonam">Thiruvonam/Shravana</option>
                        <option value="avittam">Avittam/Dhanishtha</option>
                        <option value="chathayam">Chathayam/Satabisha</option>
                        <option value="pooruruttaathi">
                          Pooruruttaathi/Poorva bhadrapada
                        </option>
                        <option value="uthrattathi">
                          Uthrattathi/Uttara bhadrapada
                        </option>
                        <option value="revathi">Revathi</option>
                      </Field>
                      <ErrorMessage
                        name="brideStar"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <h6 className="form__section-title">
                      Details of the groom
                    </h6>
                    <div className="form__group">
                      <label htmlFor="groomName" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="groomName"
                        id="groomName"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="groomName"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="groomDob" className="form__label">
                        Date of birth*
                      </label>
                      <Field
                        type="date"
                        max={new Date().getFullYear() - 17 + "-01-01"}
                        name="groomDob"
                        id="groomDob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="groomDob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="groomTob" className="form__label">
                        Time of birth*
                      </label>
                      <Field
                        type="time"
                        name="groomTob"
                        id="groomTob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="groomTob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="groomPob" className="form__label">
                        Place of birth* (nearest city)
                      </label>
                      <Field
                        type="text"
                        name="groomPob"
                        id="groomPob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="groomPob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="groomStar" className="form__label">
                        Nakshatra/Birthstar*
                      </label>
                      <Field
                        as="select"
                        name="groomStar"
                        id="groomStar"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="unknown">
                          Unknown (its fine, we will calculate it.)
                        </option>
                        <option value="aswathi">Aswathi/Ashwini</option>
                        <option value="bharani">Bharani</option>
                        <option value="karthika">Karthika/Krittika</option>
                        <option value="rohini">Rohini</option>
                        <option value="makeeram">Makeeram/Mrigashirsa</option>
                        <option value="thiruvathira">Thiruvathira/Ardra</option>
                        <option value="punartham">
                          Punartham/Punarvasu/Punarpoosam
                        </option>
                        <option value="pooyam">Pooyam/Pushya</option>
                        <option value="aayilyam">Aayilyam/Ashlesha</option>
                        <option value="makam">Makam/Magha</option>
                        <option value="pooram">Pooram/Purva Phalguni</option>
                        <option value="uthran">Uthram/Uttara Phalguni</option>
                        <option value="atham">Atham/Hasta</option>
                        <option value="chithira">Chithira/Chitra</option>
                        <option value="chothi">Chothi/Swati</option>
                        <option value="vishaakam">Vishaakham/Vishakha</option>
                        <option value="anizham">
                          Anizham/Anuradha/Anusham
                        </option>
                        <option value="thriketta">
                          Thriketta/Jyeshtha/Kettai
                        </option>
                        <option value="moolam">Moolam/Mula</option>
                        <option value="pooraadam">Pooraadam/Purvashada</option>
                        <option value="uthraadam">Uthraadam/Uthrashada</option>
                        <option value="thiruvonam">Thiruvonam/Shravana</option>
                        <option value="avittam">Avittam/Dhanishtha</option>
                        <option value="chathayam">Chathayam/Satabisha</option>
                        <option value="pooruruttaathi">
                          Pooruruttaathi/Poorva bhadrapada
                        </option>
                        <option value="uthrattathi">
                          Uthrattathi/Uttara bhadrapada
                        </option>
                        <option value="revathi">Revathi</option>
                      </Field>
                      <ErrorMessage
                        name="groomStar"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="message" className="form__label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        rows="4"
                        name="message"
                        id="message"
                        className="form__input"
                      />
                      <ErrorMessage
                        name="message"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="hidden">
                      <label>Do you align with our policy</label>
                      <Field name="policy" type="checkbox"></Field>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Apply
                      </button>
                      <button className="link_text" type="reset">
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  )
}

export default HoroscopeMatching
